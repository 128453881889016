<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="3" />
        <v-col cols="6"
          ><strong>{{ $t("advisor.title") }}</strong></v-col
        >
        <v-col cols="3" />
      </v-row>
      <v-row>
        <v-col cols="2" />
        <v-col cols="4" style="padding: 30px 12px 0 12px">{{
          $t("advisor.simple")
        }}</v-col>
        <v-col cols="4">
          <v-rating
            v-model="ratingEasy"
            color="warning"
            hover
            length="5"
            size="64"
            value="3"
            @input="updateRating"
          ></v-rating
        ></v-col>
        <v-col cols="2" />
      </v-row>
      <v-row>
        <v-col cols="2" />
        <v-col cols="4" style="padding: 30px 12px 0 12px">{{
          $t("advisor.reuse")
        }}</v-col>
        <v-col cols="4">
          <v-rating
            v-model="ratingReuse"
            color="warning"
            hover
            length="5"
            size="64"
            value="3"
            @input="updateRating"
          ></v-rating
        ></v-col>
        <v-col cols="2" />
      </v-row>
      <v-row>
        <v-col cols="2" />
        <v-col cols="4" style="padding: 30px 12px 0 12px">{{
          $t("advisor.recommend")
        }}</v-col>
        <v-col cols="4">
          <v-rating
            v-model="ratingRecommend"
            color="warning"
            hover
            length="5"
            size="64"
            value="3"
            @input="updateRating"
          ></v-rating
        ></v-col>
        <v-col cols="2" />
      </v-row>
    </v-container>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "Advisor",
  data() {
    return {
      ratingEasy: 0,
      ratingReuse: 0,
      ratingRecommend: 0,
    };
  },
  methods: {
    updateRating() {
      let cb_conf = this.$store.state.callback;
      if (this.$store.state.hasMotors) axios.get("http://localhost:3000/front");
      else {
        if (this.$store.state.timeout !== "not yet")
          window.clearTimeout(this.$store.state.timeout);
        let timeout = window.setTimeout(() => {
          window.location.href = cb_conf;
        }, process.env.VUE_APP_NOMOTOR_TIMEOUT);
        this.$store.commit({ type: "timeout", timeout: timeout });
      }
      this.$store.commit("ratings", {
        easy: this.ratingEasy,
        reuse: this.ratingReuse,
        recommend: this.ratingRecommend,
      });
    },
  },
};
</script>
